<template>

<div class="w-full h-full px-3 py-4" :class="'bg-body-'+mode">

    <div class="h-full w-full flex flex-col mb-6 overflow-auto">
        
            <div class="flex-1 overflow-y-auto">
                <All :model="model" :immediate="true" :query="query" :data.sync="datatops" v-slot="{data, loading}">
                    <div v-if="!loading" class="h-auto">
                        <div v-for="(el,index) in data.table" :key="index" class="h-auto p-2 flex flex-col mb-2 shadow_box rounded-lg" :class="'bg-box-'+mode">
                            <div class="h-6 flex flex-row justify-start items-center px-2">
                                <span class="text-xs font-semibold" :class="'text-dfont-'+mode">{{el.name}}</span>
                            </div>
                            <div class="h-auto flex flex-wrap">
                                <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">
                                        {{ translate(topindicatorname) }}<sup v-if="topindicatorname === 'Metros'">2</sup>
                                    </span>
                                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">
                                        {{ el.value | reduceBigNumbers() }}<span v-html="unit"></span>
                                    </span>
                                </div>
                                <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{ $t('growth') }}</span>
                                    <span class="text-xs font-medium" :class="{'text-green': el.growth > 0, 'text-red': el.growth <= 0}">
                                        <span v-if="el.growth > 0">+</span>
                                            {{el.growth | reduceBigNumbers()}}
                                        <span v-html="unit2"></span>
                                    </span>
                                </div>
                                <div v-if="el.weighing != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{ $t('weight' )}}</span>
                                    <span class="text-xs font-medium"
                                          :class="{'text-red': el.weighing <= 0, 'text-dfont': el.weighing > 0, 'text-dfont-darkmode': mode == 'darkmode', 'text-dfont-whitemode': mode == 'whitemode'}">
                                        <span v-if="el.weighing > 0 && topindicator > 1">+</span>
                                            {{el.weighing | reduceBigNumbers()}}<span v-html="unit2"></span>
                                    </span>
                                </div>
                                <div v-if="el.groupValue != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">vs {{ $t('company') }}</span>
                                    <span class="text-xs font-medium"
                                        :class="{'text-red': el.groupValue <= 0, 'text-dfont': el.groupValue > 0, 'text-dfont-darkmode': mode == 'darkmode', 'text-dfont-whitemode': mode == 'whitemode'}">
                                        <span v-if="el.groupValue > 0 && topindicator > 1">+</span>
                                            {{ el.groupValue | reduceBigNumbers() }}<span v-html="unit2"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="h-20 relative"><loader :loading="loading" /></div>
                </All>
            </div>
        </div>
    </div>

</template>

<script>
import basiclinechart from '@/components/basiclinechart.vue';
import { All } from '@/api/components';
import { state, actions } from '@/store';
import loader from '@/components/loader.vue';

export default {
    components: {
        basiclinechart,
        All,
        loader
    },
    data() {
        return { datatops: null }
    },
    methods: {
        translate(word) {
            switch (word) {
                case 'Paises':
                    return this.$t("countries")
                    break;
                case 'Metros':
                    return this.$t("meters")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                case 'Calidad':
                    return this.$t("quality")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Todos los continentes':
                    return this.$t("allContinents")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
            }
        },
        toFilters() {
            actions.setLastPage(this.$route.name)
            this.$router.push('filterstop')
        }
    },
    computed: {
        monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
        },
        query() {
            if (state.comercialSelected && state.comercialSelected.Id) {
                return{
                    period: state.topPeriod,
                    Dimension: state.topIndicator,
                    Group: state.topDimension,
                    Place: state.topTypePlace,
                    PlaceId: state.topPlace,
                    Company: state.idCompany,
                    SaleType: state.saleTypeSelected,
                    IdUser: state.comercialSelected.Id,
                    IndustrialGroup: state.industrialGroup,
                    ClientType: state.typeclienttops,
                     month: state.month
                }
            }
            else {
                return{
                    period: state.topPeriod,
                    Dimension: state.topIndicator,
                    Group: state.topDimension,
                    Place: state.topTypePlace,
                    PlaceId: state.topPlace,
                    Company: state.idCompany,
                    SaleType: state.saleTypeSelected,
                    Zone: state.idZone,
                    IndustrialGroup: state.industrialGroup,
                    ClientType: state.typeclienttops,
                     month: state.month
                }
            }
        },
        periodo() {
            switch (state.topPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        unit() {
            let unit = ' €'
            if (state.topIndicator == 1) { unit = ' m<sup>2</sup>' }
            if (state.topIndicator == 3) { unit = ' %' }
            if (state.topIndicator == 2) { unit = '€/m<sup>2</sup>' }
            return unit
        },
        unit2() {
            let unit = ' %'
            if (state.topIndicator == 2) { unit = '€/m<sup>2</sup>' }
            if (state.topIndicator == 3) { unit = '' }
            return unit
        },
        topdimension() { return state.topDimension },
        topplace() { return state.topPlace },
        topindicator() { return state.topIndicator },
        topdimensionname() { return state.topDimensionName },
        topindicatorname() {
            if (state.topIndicatorName !== 'Metros cuadrados') { return state.topIndicatorName }
            else { return 'Metros' }
        },
        topplacename() { return state.topPlaceName },
        nameCompany() { return state.companySelected },
        model() {
            if (state.comercialSelected && state.comercialSelected.Id) { return 'Comercialidtops' }
            else { return 'Comercialzonetops' }
        },
        comercial() { return state.zone },
        clienttype() { return state.typeclienttops },
        mode(){
            return state.mode
        }
    },
    mounted() {
        if (state.zoneSelected == 'Exportación') {
            if (state.topTypePlace == 'Province') {
                actions.setTopTypePlace('Country')
                actions.setTopPlaceName('Todos los Paises')
                actions.setActualTopTypePlace('Countrytops')
            }
        }
        if (state.zoneSelected == 'Nacional') {
            if (state.topTypePlace == 'Country' || state.topTypePlace == 'Continent') {
                actions.setTopTypePlace('Province')
                actions.setTopPlaceName('Todas las Provincias')
                actions.setActualTopTypePlace('Provincetops')
            }
        }
        if (state.topDimension == 'Company') {
            if (state.zoneSelected == 'Nacional') {
                actions.setTopDimension('Province');
                actions.setTopDimensionName('Provincias de España');
            }
            else {
                actions.setTopDimension('Country');
                actions.setTopDimensionName('Paises');
            }
        }
    }
}
</script>